export interface CardType {
  name: string;
  id: number;
  description: string[];
  img: {
    oneX: string
    twoX: string
    threeX: string
  }
}

export enum Step {
  LIST = "LIST",
  ROTATE = "ROTATE",
  RESULT = "RESULT"
}
