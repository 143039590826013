import { CardType } from "./types";
import { shuffleArray } from "./utils";

export const cards: CardType[] = [
  {
    id: 1,
    name: "Туз Пентаклей",
    description: [
      `Туз Пентаклей указывает на материальный ресурс, который можно вложить или заработать. Например, вы вспомните про забытый банковский счёт с небольшой суммой, найдёте 500 рублей в кармане зимней куртки с прошлого года или получите небольшую прибавку к зарплате. `,
      `Также карта Таро говорит о появлении дополнительного времени, которое вы можете потратить на работу, полезное дело, на себя или своих близких. Кроме того, вы можете получить символический подарок или физическую помощь.`,
      `Карта дня — про возможность, хоть и без гарантии результата. Поэтому важно грамотно использовать предоставленный вам шанс.`,
    ],
    img: {
      oneX: "cardOfDay/pentacly/1-pent1x.png",
      twoX: "cardOfDay/pentacly/1-pent2x.png",
      threeX: "cardOfDay/pentacly/1-pent3x.png",
    },
  },
  {
    id: 3,
    name: "Двойка Пентаклей",
    description: [
      `Карта Таро говорит о нестабильности, несерьёзности, день будет наполнен игривостью, изворотливостью. Вы попытаетесь совместить несколько дел и попробуете усидеть на двух стульях. Но даже если что-то одно упустите, то не расстроитесь — есть же второе.`,
      `Карта дня подсказывает, что придётся балансировать на грани: сегодня у вас есть всё, а завтра может не быть ничего. Например, сдельная работа или два претендента на сердце. Но вас совсем не напрягает эта ситуация (даже неприятная). Вы оптимистично смотрите на жизнь. Однако Двойка Пентаклей советует во всём знать меру, не быть слишком инфантильными.`,
    ],
    img: {
      oneX: "cardOfDay/pentacly/2-pent1x.png",
      twoX: "cardOfDay/pentacly/2-pent2x.png",
      threeX: "cardOfDay/pentacly/2-pent3x.png",
    },
  },
  {
    id: 4,
    name: "Тройка Пентаклей",
    description: [
      `Вам предстоит действовать в команде; карта дня говорит, что потребуется опыт специалистов из разных направлений. Все будут работать сообща и помогать друг другу. Например, бизнесмен, иллюстратор и программист. А может, начнёте отношения с коллегой, и в пару будут постоянно влезать третьи лица.`,
      `Также Тройка Пентаклей сообщает об упорной работе и мастерстве. Будет оценивание ваших трудов, например, защита групповой презентации или экзамен, которые пройдут удачно. Карта Таро не гарантирует небывалого успеха, а указывает на первые плоды деятельности. Но эта работа хорошая, слаженная и эффективная.`,
    ],
    img: {
      oneX: "cardOfDay/pentacly/3-pent1x.png",
      twoX: "cardOfDay/pentacly/3-pent2x.png",
      threeX: "cardOfDay/pentacly/3-pent3x.png",
    },
  },
  {
    id: 5,
    name: "Четвёрка Пентаклей",
    description: [
      `Всё пойдёт по плану, стабильно и надёжно. Поэтому карта дня указывает на скуку и страх изменений. Например, есть работа, денег мало, но другую не ищете — боитесь потерять материальную безопасность. Или у вас серьёзные отношения, знаете все особенности партнёра, и ничего нового не будет, но вы терпите — боитесь одиночества или знакомств с новыми людьми.`,
      `Также Четвёрка Пентаклей говорит о скупости и финансовом застое из-за отсутствия развития. Покажется, что имеете мало, поэтому начнёте себя ограничивать.`,
      `Карта Таро советует взбодриться, наградить себя за труд и подумать, как можно изменить ситуацию.`,
    ],
    img: {
      oneX: "cardOfDay/pentacly/4-pent1x.png",
      twoX: "cardOfDay/pentacly/4-pent2x.png",
      threeX: "cardOfDay/pentacly/4-pent3x.png",
    },
  },
  {
    id: 6,
    name: "Пятёрка Пентаклей",
    description: [
      `Пятёрка Пентаклей показывает нехватку материальных ресурсов. Например, возьмёте на себя несколько рабочих проектов, потратите много сил и времени, а денег всё равно будет мало.`,
      `Также карта Таро отражает неудовлетворённые потребности, вы почувствуете себя отвергнутыми. Например, расстанетесь с партнёром из-за ощущения, что только вы вкладываетесь в отношения и ничего не получаете взамен.`,
      `Но ваша позиция надуманная. Вы не всё потеряете (деньги и другие ресурсы останутся), но ощущать себя будете на дне. Карта дня советует перестать жалеть себя. Оглянитесь вокруг — у вас много новых возможностей.`,
    ],
    img: {
      oneX: "cardOfDay/pentacly/5-pent1x.png",
      twoX: "cardOfDay/pentacly/5-pent2x.png",
      threeX: "cardOfDay/pentacly/5-pent3x.png",
    },
  },
  {
    id: 7,
    name: "Шестёрка Пентаклей",
    description: [
      `Следите за обменом своих ресурсов. Карта Таро советует отдавать и потом ждать результата. Например, займётесь спортом — восстановите здоровье, вложите капитал — получите прибыль.`,
      `Если вам нужна помощь — обратитесь за ней. Карта дня говорит, что в этом нет ничего плохого. Вы получите поддержку, увеличение зарплаты или желанную вещь, если попросите.`,
      `А если можете сами делиться — делайте это, но искренне. Шестёрка Пентаклей говорит не поступать высокомерно («держи, ты нищий, тебе можно», или «так и быть схожу с тобой в кино, ты же меня любишь»). И не пытайтесь спасать людей, которые в этом не нуждаются.`,
    ],
    img: {
      oneX: "cardOfDay/pentacly/6-pent1x.png",
      twoX: "cardOfDay/pentacly/6-pent2x.png",
      threeX: "cardOfDay/pentacly/6-pent3x.png",
    },
  },
  {
    id: 8,
    name: "Семёрка Пентаклей",
    description: [
      `Недовольство плодами своего труда, обесценивание. Карта дня показывает классные результаты, но не «вау», поэтому они вам не угодят. Решите, что приложили слишком много сил зря. Например, идеализировали отношения и вдруг осознаете, что в вашей жизни всё не так. Но поймёте, что сами надумали нереальное, а партнёр на самом деле хороший.`,
      `Семёрка Пентаклей указывает на внутренний кризис, важно оценить свой прогресс, действия и ошибки. У вас есть шанс исправить ситуацию. Карта Таро говорит — вы осознаете, куда стремиться, поймёте, что делать, чтобы результат множился без вашего участия в дальнейшем.`,
    ],
    img: {
      oneX: "cardOfDay/pentacly/7-pent1x.png",
      twoX: "cardOfDay/pentacly/7-pent2x.png",
      threeX: "cardOfDay/pentacly/7-pent3x.png",
    },
  },
  {
    id: 9,
    name: "Восьмёрка Пентаклей",
    description: [
      `Восьмёрка Пентаклей указывает на усердную работу и рутину. Но вам она понравится и принесёт хорошие деньги.`,
      `А ещё карта дня говорит, что ресурсы будут множиться без вашего участия. Например, ранее вы уже наладили быт в паре, и теперь отношения начнут работать на вас. Вам с партнёром всё понятно, хорошо и удобно. Такое однообразие дома помогает сосредоточиться на своей реализации и работе.`,
      `Также карта Таро говорит о профессионализме. Вы не добьётесь нового, но займётесь укреплением полученных результатов. Выстроите деятельность так, чтобы она стала надёжной, а финансовый результат стабильным.`,
    ],
    img: {
      oneX: "cardOfDay/pentacly/8-pent1x.png",
      twoX: "cardOfDay/pentacly/8-pent2x.png",
      threeX: "cardOfDay/pentacly/8-pent3x.png",
    },
  },
  {
    id: 10,
    name: "Девятка Пентаклей",
    description: [
      `Расслабьтесь и насладитесь результатами своей работы. Карта Таро говорит о проживании того, что вы уже создали. Ресурсы у вас будут сами появляться. Например, вы начнёте сдавать ранее купленную квартиру и получите стабильный пассивный доход.`,
      `Также Девятка Пентаклей указывает на вашу самодостаточность и самостоятельность. Но делиться имеющимся вы не собираетесь, поэтому решите уединиться и насладиться одиночеством. Например, на флирт ответите отказом, чтобы не тратить время на сомнительного человека. Карта дня говорит, что спешить некуда, можно посмаковать жизнь, но не становитесь затворником.`,
    ],
    img: {
      oneX: "cardOfDay/pentacly/9-pent1x.png",
      twoX: "cardOfDay/pentacly/9-pent2x.png",
      threeX: "cardOfDay/pentacly/9-pent3x.png",
    },
  },
  {
    id: 11,
    name: "Десятка Пентаклей",
    description: [
      `Проведёте день в большом коллективе. Либо карта дня говорит о семье: займётесь делами, финансами, поддержанием ценностей. Всё рассматриваете через призму семьи. Например, выбираете партнёра, который понравится родным. А может решите вложиться в семейный бизнес или купить большой дом. Также Десятка Пентаклей указывает на более приземлённые вещи: подарки, праздничный стол или ужин у родных.`,
      `Либо карта Таро говорит, что на работе вы деталь большой машины и зависите от компании, которая воспринимается как общая семья. Например, своих денег не много, но вы бухгалтер и управляете чужими финансами.`,
    ],
    img: {
      oneX: "cardOfDay/pentacly/10-pent1x.png",
      twoX: "cardOfDay/pentacly/10-pent2x.png",
      threeX: "cardOfDay/pentacly/10-pent3x.png",
    },
  },
  {
    id: 12,
    name: "Паж Пентаклей",
    description: [
      `Карта Таро сообщает, что вы с головой погрузитесь в задачу, работу или учёбу. Начнёте тренировать свои навыки или в прямом смысле пойдёте на спортивную тренировку.`,
      `В отношениях карта дня говорит — вам непонятно, как общаться с партнёром, как проявлять эмоции. Поэтому будете стараться ему угодить, выражать чувства делом: приготовите еду, повесите полку, поможете. `,
      `Также вы можете получить немного денег или любой другой ресурс — Паж Пентаклей советует вложить это в своё развитие. Например, найдёте стартовый капитал для открытия своего дела. Или получите скидку к интересующему вам обучающему курсу.`,
    ],
    img: {
      oneX: "cardOfDay/pentacly/pazh-pent1x.png",
      twoX: "cardOfDay/pentacly/pazh-pent2x.png",
      threeX: "cardOfDay/pentacly/pazh-pent3x.png",
    },
  },
  {
    id: 13,
    name: "Рыцарь Пентаклей",
    description: [
      `Рыцарь Пентаклей — сконцентрируйтесь на важном деле. Постепенно развивайте дело, укрепляйте отношения, позиции. Продвигайтесь медленно и аккуратно. Просчитывайте всё возможное, анализируйте, насколько это прибыльно и эффективно.`,
      `Не торопите события, а закладывайте в три раза больше времени, чем рассчитывали. Но карта дня говорит не застревать на одном месте. Отбросьте иллюзии, проявите упорство, воздержитесь от конфликтов и стороннего вмешательства.`,
      `Также карта Таро говорит о решении материальных вопросов. А ещё вы начнёте куда-то собираться, но всё будет предсказуемо.`,
    ],
    img: {
      oneX: "cardOfDay/pentacly/knight-pent1x.png",
      twoX: "cardOfDay/pentacly/knight-pent2x.png",
      threeX: "cardOfDay/pentacly/knight-pent3x.png",
    },
  },
  {
    id: 14,
    name: "Королева Пентаклей",
    description: [
      `Сфокусируйтесь на материальном: не убегайте в фантазии и мысли о будущем, а находитесь в настоящем моменте. Карта Таро советует спокойно и последовательно решать рабочие вопросы. Или заняться бытовыми делами: приготовьте что-то вкусное, приберитесь, создайте уют.`,
      `Вы хорошо зарабатываете, чувствуете стабильность и уверенность. Но Королева Пентаклей говорит — вы больше отдаёте, поэтому в других сферах жизни рядом есть нахлебники и постоянно просящие взаймы. Карта дня советует не проваливаться в гиперопеку и спасательство. Не ведитесь на манипуляции и не позволяйте другим на себе паразитировать.`,
    ],
    img: {
      oneX: "cardOfDay/pentacly/queen-pent1x.png",
      twoX: "cardOfDay/pentacly/queen-pent2x.png",
      threeX: "cardOfDay/pentacly/queen-pent3x.png",
    },
  },
  {
    id: 15,
    name: "Король Пентаклей",
    description: [
      `Не сорите деньгами, будьте скромнее и ответственно подходите к тратам. Карта дня сообщает — прироста ресурсов не будет, сфокусируйтесь на стабилизации имеющегося. Никуда особо не вкладывайтесь, а делайте так, чтобы деньги работали сами на себя. Но не становитесь чересчур жадными.`,
      `Также вы можете контактировать с человеком как Король Пентаклей, который регулирует ресурсы. Например, с начальником будете обсуждать повышение зарплаты и обосновывать свою позицию.`,
      `А ещё карта Таро указывает на выгодный договор с большой организацией, предприятием, заводом. Или на покупку чего-то тяжелого и крупного.`,
    ],
    img: {
      oneX: "cardOfDay/pentacly/king-pent1x.png",
      twoX: "cardOfDay/pentacly/king-pent2x.png",
      threeX: "cardOfDay/pentacly/king-pent3x.png",
    },
  },
  {
    id: 16,
    name: "Туз Мечей",
    description: [
      `Туз Мечей говорит, что вы осознаете то, что раньше не получалось разглядеть. Но это произойдёт не в расслабленной и комфортной обстановке, а в давящей атмосфере, откровенном разговоре или конфликте. Карта Таро предупреждает, что новая информация может ранить. Но зато ваше решение будет жёстким и волевым.`,
      `Кроме того, карта Дня сообщает о ситуациях, которые выносят вам приговор, словно меч: на экзамене, на собеседовании, в отношениях и др. Также можете получить ответ от государственных органов или суда, подписать договор, претензию. Но в этих ситуациях всегда будет конфликт и давление кого-то над кем-то.`,
    ],
    img: {
      oneX: "cardOfDay/swords/1-swords1x.png",
      twoX: "cardOfDay/swords/1-swords2x.png",
      threeX: "cardOfDay/swords/1-swords3x.png",
    },
  },
  {
    id: 17,
    name: "Двойка Мечей",
    description: [
      `Вы закроете глаза на происходящее и откажетесь делать важный выбор. Из-за нежелания видеть правду будет казаться, что всё против вас. Поэтому карта Таро говорит, что вы займёте пассивную позицию: не будете проявлять активность, идти навстречу людям и влиять на события вокруг.`,
      `Также Двойка Мечей указывает на неразбериху в мыслях. Вы будете терзаться сомнениями и колебаться: что делать дальше, как себя вести, уходить ли от партнёра, менять ли работу, соглашаться ли на повышение и др. Чтобы вернуть себе стабильность, карта дня советует объективно посмотреть на происходящее и разобраться с этим.`,
    ],
    img: {
      oneX: "cardOfDay/swords/2-swords1x.png",
      twoX: "cardOfDay/swords/2-swords2x.png",
      threeX: "cardOfDay/swords/2-swords3x.png",
    },
  },
  {
    id: 18,
    name: "Тройка Мечей",
    description: [
      `Карта дня говорит о выявлении правды и избавлении от иллюзий. А значит у вас случится неприятное событие, которое разочарует и ранит, но покажет реальность. Например, узнаете, что друг наговорил о вас с три короба общему знакомому, и вы остро воспримете эту новость. Или расстроитесь, что сделали классный, на ваш взгляд, проект, а его не оценили.`,
      `Карта Таро предупреждает — слишком завышенные ожидания могут сбросить вас с небес на землю. Помните, Тройка Мечей очень болезненна в моменте, но негативные эмоции пройдут быстро. Вероятно, через некоторое время вы и не вспомните про это предательство.`,
    ],
    img: {
      oneX: "cardOfDay/swords/3-swords1x.png",
      twoX: "cardOfDay/swords/3-swords2x.png",
      threeX: "cardOfDay/swords/3-swords3x.png",
    },
  },
  {
    id: 19,
    name: "Четвёрка Мечей",
    description: [
      `Сегодня нужно взять паузу и отдохнуть. Скорее всего, и у вас самих не будет мотивации что-то делать. Карта Таро сообщает, что вы станете более пассивными, столкнётесь с разными ограничениями, которые потребуют от вас ожидания, или вообще можете заболеть.`,
      `Даже если навалилось много дел, у вас не получится их эффективно выполнить. Поэтому Четвёрка Мечей советует вам не мучить себя, а восстановить физические силы: выспаться, расслабиться, сходить на массаж. И также карта дня говорит успокоить ваши мысли, внести в них ясность и помедитировать. На свежую голову к вам обязательно придут отличные новые идеи.`,
    ],
    img: {
      oneX: "cardOfDay/swords/4-swords1x.png",
      twoX: "cardOfDay/swords/4-swords2x.png",
      threeX: "cardOfDay/swords/4-swords3x.png",
    },
  },
  {
    id: 20,
    name: "Пятёрка Мечей",
    description: [
      `Пятёрка Мечей говорит, что день может сложиться по-разному — отталкивайтесь от того, что вам ближе. С одной стороны, карта дня показывает проигрыш и унижение. Например, можете узнать, что все ваши прежние убеждения были ложью: партнёр подло бросит, нечестно уволят с работы, дадут неверную информацию и подставят или присвоят ваши заслуги себе.`,
      `С другой стороны, карта Таро говорит о вашей победе, которая не принесёт удовольствия. Скорее всего, вы добьётесь своего не совсем честным путём, например, унизите другого человека или отберёте у него что-то. Поэтому ваши впечатления от достижения цели будут смазаны.`,
    ],
    img: {
      oneX: "cardOfDay/swords/5-swords1x.png",
      twoX: "cardOfDay/swords/5-swords2x.png",
      threeX: "cardOfDay/swords/5-swords3x.png",
    },
  },
  {
    id: 21,
    name: "Шестёрка Мечей",
    description: [
      `Карта Таро говорит, что вы захотите сбежать от проблем и из-за этого откажетесь что-то делать: контактировать с людьми, следовать намеченному плану и др. Например, поймёте, что вас ждёт расставание, поэтому сами по-английски уйдёте из отношений или с работы. Но это не решит проблем. Чтобы потом не наступить на те же грабли, усвойте урок из ситуации.`,
      `Также карта дня сообщает, что вы сами можете столкнуться с избеганием. Например, вызовете сантехника, он придёт, но откажется от работы.`,
      `А ещё Шестёрка Мечей говорит, что вы отправитесь в дорогу: разъезды по городу, командировка, поездка в отпуск, переезд.`,
    ],
    img: {
      oneX: "cardOfDay/swords/6-swords1x.png",
      twoX: "cardOfDay/swords/6-swords2x.png",
      threeX: "cardOfDay/swords/6-swords3x.png",
    },
  },
  {
    id: 22,
    name: "Семёрка Мечей",
    description: [
      `Семёрка Мечей указывает на синдром самозванца. Вы посчитаете себя недостойными и начнёте обманывать себя и других с целью понравиться, соответствовать обществу.`,
      `Также карта Таро говорит — сложится ситуация, в которой вы ощутите отвержение. Решите, что честно ничего не сможете добиться, станете подстраиваться под обстановку, хитрить. А ещё карта дня говорит о двойной жизни. Вас могут вводить в заблуждение близкие. И вы рискуете столкнуться с кражей вещей.`,
      `Чтобы добиться желаемого, ориентируйтесь не на мнение других, а на свою уникальность. У вас хорошо развиты навыки дипломатии, используйте их.`,
    ],
    img: {
      oneX: "cardOfDay/swords/7-swords1x.png",
      twoX: "cardOfDay/swords/7-swords2x.png",
      threeX: "cardOfDay/swords/7-swords3x.png",
    },
  },
  {
    id: 23,
    name: "Восьмёрка Мечей",
    description: [
      `Вы столкнётесь с внутренним кризисом. Карта дня говорит, что сами себя заточите или решите остаться в существующей «тюрьме». Например, на работе больше невозможно находиться, но уйти боитесь из-за страха не найти новое место. Или отношения невыносимые, в них много ограничений. Или вы живёте с алкоголиком (истеричкой) и терпите его поведение.`,
      `Карта Восьмёрка Мечей говорит о пассивности. Вы бездействуете из-за страха неудачи, поэтому не получаете желаемого результата: не повышают в должности, не замечает любимый человек, не развиваетесь. Карта Таро советует смело шагать в новое — хуже, чем есть, уже не будет.`,
    ],
    img: {
      oneX: "cardOfDay/swords/8-swords1x.png",
      twoX: "cardOfDay/swords/8-swords2x.png",
      threeX: "cardOfDay/swords/8-swords3x.png",
    },
  },
  {
    id: 24,
    name: "Девятка Мечей",
    description: [
      `Карта Таро предупреждает — вы столкнётесь со своими надуманными страхами. Сами начнёте накручивать себя на пустом месте. Вас понесёт, невозможно будет остановить поток одних и тех же мыслей. Например, время 00:00, вы ждёте своего ребёнка-подростка домой с прогулки, и начнёте прокручивать в голове негативные сценарии, обзванивать друзей и больницы.`,
      `Девятка Мечей советует вам обратить внимание на свою психику, потому что ваши страхи могут начать вас сжирать. Почему вы так этого боитесь?`,
      `Также карта дня говорит о ваших переживаниях из-за наличия нескольких вариантов и необходимости сделать выбор.`,
    ],
    img: {
      oneX: "cardOfDay/swords/9-swords1x.png",
      twoX: "cardOfDay/swords/9-swords2x.png",
      threeX: "cardOfDay/swords/9-swords3x.png",
    },
  },
  {
    id: 25,
    name: "Десятка Мечей",
    description: [
      `Вы поймёте, что верили не в то, смиритесь с проигрышем — карта дня говорит про завершение важного этапа в жизни. Например, женщина встречается с женатым мужчиной, планирует совместное будущее в надежде, что любимый скоро разведётся. Но узнаёт, что он не собирается это делать, к тому же у него будет ребёнок. Десятка Мечей указывает на крах планов, но и шанс построить жизнь лучше.`,
      `Ощущения будут неприятные, но важно отгоревать, чтобы увидеть перед собой новые горизонты. Карта Таро говорит, что вам нужно проанализировать свою стратегию и усвоить ошибки, именно дадут возможность двигаться дальше.`,
    ],
    img: {
      oneX: "cardOfDay/swords/10-swords1x.png",
      twoX: "cardOfDay/swords/10-swords2x.png",
      threeX: "cardOfDay/swords/10-swords3x.png",
    },
  },
  {
    id: 26,
    name: "Паж Мечей",
    description: [
      `Карта Таро говорит о недоверии и подозрительности. Из-за тревожности вы начнёте выведывать информацию: подслушивать, читать чужие переписки, взламывать чей-то телефон.`,
      `Паж Мечей указывает на возникновение зависти, которая может подтолкнуть вас к мелкой мести за спиной. Или же чувство незащищённости — всё хорошо, но что-то беспокоит. В реальности причин для опасений может не быть, но вам они покажутся ощутимыми.`,
      `При этом вы не до конца сформируете своё мнение, почувствуете неуверенность, неясность происходящего. Карта дня советует не добывать информацию втихую, а прямо поговорить с человеком.`,
    ],
    img: {
      oneX: "cardOfDay/swords/pazh-swords1x.png",
      twoX: "cardOfDay/swords/pazh-swords2x.png",
      threeX: "cardOfDay/swords/pazh-swords3x.png",
    },
  },
  {
    id: 27,
    name: "Рыцарь Мечей",
    description: [
      `У вас появится желание доминировать, которое приведёт к конфликту с кем-то из окружения. Карта дня говорит, что шумная ссора будет сопровождаться агрессией, критикой всех и вся. Вы начнёте с фанатизмом отстаивать свою идею и останетесь глухи к мнению других. Или вы можете вести себя так, защищаясь от другого человека, который будет как Рыцарь Мечей.`,
      `Кроме того, вы можете вступить в дебаты или спор. Карта Таро указывает на получение штрафов, претензий, судебных решений. Чтобы избежать этого, не делайте поспешных выводов, мыслите ясно и поступайте здраво. Помните: сколько людей, столько и мнений.`,
    ],
    img: {
      oneX: "cardOfDay/swords/knight-swords1x.png",
      twoX: "cardOfDay/swords/knight-swords2x.png",
      threeX: "cardOfDay/swords/knight-swords3x.png",
    },
  },
  {
    id: 28,
    name: "Королева Мечей",
    description: [
      `Вас обидят или подорвут доверие, в результате начнёте себя вести цинично. Вы станете более агрессивными, критичными, будете давать резкие и неприятные ответы, хоть и правдивые. Вы полностью выключите эмоции, будете с холодной головой решать дела. Карта Таро показывает одиночество: вы самодостаточны и со всем сами справитесь. Такой подход поможет поступать грамотно, действовать чётко и быстро.`,
      `А если вы столкнётесь с человеком, как Королева Мечей, то сохраняйте с ним дистанцию, чтобы не нарваться на критику и лишения.`,
      `Кроме того, карта дня указывает на разные проверки: аттестация, аудит, налоги.`,
    ],
    img: {
      oneX: "cardOfDay/swords/queen-swords1x.png",
      twoX: "cardOfDay/swords/queen-swords2x.png",
      threeX: "cardOfDay/swords/queen-swords3x.png",
    },
  },
  {
    id: 29,
    name: "Король Мечей",
    description: [
      `Вы уверены в себе и надёжны, но считаете, что есть ваше мнение и неправильное. Карта дня говорит — вы начнёте игнорировать людей, будете выносить свои приговоры и односложно отвечать на вопросы.`,
      `Также карта Таро указывает на суровость и жёсткость, в том числе и к себе. Вы контролируете свои мысли и слова, понимаете собственные и чужие чувства — вам нужно немного расслабиться, чтобы снизить напряжение.`,
      `А ещё Король Мечей говорит, что вы столкнётесь с отказами и запретами. Возможно, придётся общаться с военными или полицией. Чтобы всё прошло хорошо, будьте более гибкими и мягкими, идите навстречу.`,
    ],
    img: {
      oneX: "cardOfDay/swords/king-swords1x.png",
      twoX: "cardOfDay/swords/king-swords2x.png",
      threeX: "cardOfDay/swords/king-swords3x.png",
    },
  },
  {
    id: 30,
    name: "Туз Жезлов",
    description: [
      "Туз Жезлов — появится вдохновение и страстное желание, но не факт, что вы реализуете это. Например, в воскресенье решите с понедельника бегать — но мало кто реально это выполняет. Также карта Таро говорит о появлении амбиций и желаний показать свой статус через высокую должность, дорогие аксессуары, презентабельную машину.",
      "Кроме того, карта дня советует направить силы на реализацию новой идеи. Подумайте, чего вы хотите. Сможете добиться желаемого, если приложите усилия и проявите себя. Например, вырастите в должности, если успешно выполните новый проект. Действуйте смело, решительно, креативно.",
    ],
    img: {
      oneX: "cardOfDay/rods/1-rods1x.png",
      twoX: "cardOfDay/rods/1-rods2x.png",
      threeX: "cardOfDay/rods/1-rods3x.png",
    },
  },
  {
    id: 31,
    name: "Двойка Жезлов",
    description: [
      "Карта Таро говорит о скуке — вам нужен драйв, а его не будет. Вы завязнете в выборе между равнозначными вариантами. Перед вами развернутся большие возможности, и вы захотите ими воспользоваться, но не будете знать, как лучше поступить. В итоге получится, что энергии много, но она не используется из-за ваших сомнений.",
      "Ещё Двойка Жезлов указывает на пассивность. Будете оценивать перспективы, выстраивать стратегии, проводить исследования, вместо того, чтобы просто сделать, что от вас зависит. Поэтому карта дня говорит меньше думать, больше опираться на свои внутренние ощущения и действовать хоть как-то.",
    ],
    img: {
      oneX: "cardOfDay/rods/2-rods1x.png",
      twoX: "cardOfDay/rods/2-rods2x.png",
      threeX: "cardOfDay/rods/2-rods3x.png",
    },
  },
  {
    id: 32,
    name: "Тройка Жезлов",
    description: [
      "Вы получите первые результаты в важной сфере. Но карта дня говорит — вам не хватает энтузиазма, чтобы иметь больше. Например, за выполненный проект вас наградят не ростом зарплаты, а премией. Или пойдёте на второе свидание, если первое провели хорошо, вместо начала серьёзных отношений.",
      "Кроме того, карта Таро говорит об активном бездействии. В работе вы можете надеяться на плановое повышение, а начальство ждёт от вас результатов. Или в отношениях ожидаете, что партнёр начнёт проявлять активность, и он хочет от вас того же.",
    ],
    img: {
      oneX: "cardOfDay/rods/3-rods1x.png",
      twoX: "cardOfDay/rods/3-rods2x.png",
      threeX: "cardOfDay/rods/3-rods3x.png",
    },
  },
  {
    id: 33,
    name: "Четвёрка Жезлов",
    description: [
      "Четвёрка Жезлов гарантирует хорошее настроение и повод отметить свои прежние заслуги. Например, на работе будет большой корпоратив, показывающий рост компании. Или вы устроите вечеринку по поводу успешного запуска собственного продукта. А может с любимым человеком выйдете в свет, чтобы показать, какая вы замечательная пара. Также карта дня говорит о семейных посиделках, праздниках.",
      "Если вы ждёте результата или решения вопроса, то их не будет. Эта карта Таро — про передышку; она говорит, что у вас всё и так хорошо. Не ждите новых продаж или покупок, а стабилизируйте то, что появилось у вас раньше.",
    ],
    img: {
      oneX: "cardOfDay/rods/4-rods1x.png",
      twoX: "cardOfDay/rods/4-rods2x.png",
      threeX: "cardOfDay/rods/4-rods3x.png",
    },
  },
  {
    id: 34,
    name: "Пятёрка Жезлов",
    description: [
      "Карта Таро отражает запутанную ситуацию с высокой конкуренцией. Вы не сможете договориться с людьми, несмотря на общую цель. Все будут мериться силами, доказывая, кто круче.",
      "При этом карта дня говорит о честной борьбе, без подстав. Такое поведение подходит работе, где каждый стремится повысить свои результаты и стать лучшим сотрудником года. Но в личных взаимоотношениях оно создаёт проблемы.",
    ],
    img: {
      oneX: "cardOfDay/rods/5-rods1x.png",
      twoX: "cardOfDay/rods/5-rods2x.png",
      threeX: "cardOfDay/rods/5-rods3x.png",
    },
  },
  {
    id: 35,
    name: "Шестёрка Жезлов",
    description: [
      "Вы победите и получите желаемое. Карта дня говорит, что на работе или в социальных достижениях вы почувствуете себя на коне. А в отношениях — сконцентрируетесь на себе и позволите другим бегать вокруг вас. Иногда можно потешить своё эго и расслабиться, но Шестёрка Жезлов предупреждает: не затягивайте с таким поведением.",
      "Если вы засиделись в тени — начните больше проявляться. Превзойдите конкурентов и ведите за собой других людей. Но карта Таро советует не быть чересчур высокомерными, не проявлять манию величия. Иначе начнёте относиться ко всему, как к полю боя: только победа или полный проигрыш.",
    ],
    img: {
      oneX: "cardOfDay/rods/6-rods1x.png",
      twoX: "cardOfDay/rods/6-rods2x.png",
      threeX: "cardOfDay/rods/6-rods3x.png",
    },
  },
  {
    id: 36,
    name: "Семёрка Жезлов",
    description: [
      "Вы столкнётесь с осуждениями. Но карта Таро говорит — если на вас нападают, значит вы стоите выше остальных. Однако у вас зыбкое положение. Поэтому сначала себя убедите, что с вами всё в порядке, а потом уже других. Также карта дня советует быть бдительными, в любой момент вас могут сбросить с «пьедестала».",
      "Например, родным может не понравиться ваш партнёр, любимому человеку — смена вашей деятельности, начальнику будет не по душе ваш новый стиль. В подобных случаях Семёрка Жезлов говорит о внутренней опоре на себя. Уверенно отстаивайте свои позиции и мнение, но без агрессии, спокойно, методично.",
    ],
    img: {
      oneX: "cardOfDay/rods/7-rods1x.png",
      twoX: "cardOfDay/rods/7-rods2x.png",
      threeX: "cardOfDay/rods/7-rods3x.png",
    },
  },
  {
    id: 37,
    name: "Восьмёрка Жезлов",
    description: [
      "События закрутятся быстро и создадут суету. По карте Восьмёрка Жезлов появится разная информация и противоречивые новости, которые начнут резко и активно менять ситуацию. Дополнительное напряжение создадут действия людей. Они начнут тянуть в разные стороны, не давая результата. Вам предстоит удержать их всех вместе.",
      "Также карта Таро сообщает — вы и сами можете поступить импульсивно. Например, появится сильное желание похудеть, но без чётких действий и с частой сменой решения.",
    ],
    img: {
      oneX: "cardOfDay/rods/8-rods1x.png",
      twoX: "cardOfDay/rods/8-rods2x.png",
      threeX: "cardOfDay/rods/8-rods3x.png",
    },
  },
  {
    id: 38,
    name: "Девятка Жезлов",
    description: [
      "Впереди напряжённый и тревожный день. Карта Таро говорит о страхе повторения старых ошибок и проживания прошлого опыта заново. Но вам нужно собрать последние силы и выстоять ещё немного — и будет отдых. Например, завершите все отчёты перед отпуском. Если не справляетесь, то попросите о помощи.",
      "Также карта дня говорит — вы отстранитесь от развития или всего нового. Испугаетесь, что нужно усердно трудиться как раньше, но уже нет столько сил. Например, прогорели в бизнесе и больше не пытаетесь. Или не вступаете в отношения из-за разбитого сердца. Девятка Жезлов призывает к смелости и движению дальше.",
    ],
    img: {
      oneX: "cardOfDay/rods/9-rods1x.png",
      twoX: "cardOfDay/rods/9-rods2x.png",
      threeX: "cardOfDay/rods/9-rods3x.png",
    },
  },
  {
    id: 39,
    name: "Десятка Жезлов",
    description: [
      "Десятка Жезлов говорит о сильном перегрузе и желании сдаться. У вас слишком много дел, обязательств, ответственности. Вы поймёте, что устали и перегорели, вас больше не зажигает поставленная цель и хочется обновлений. Поэтому карта Таро предупреждает: вероятно, вы всё бросите, не получив результата.",
      "Ваш отказ от дальнейших действий будет похож на предательство. Например, поймёте, что больше не справляетесь с рабочим проектом, выйдете из него и подставите свою компанию. Но карта дня говорит — в подобных ситуациях придётся выбирать, что вам важнее: своё здоровье, спокойствие или чужие интересы.",
    ],
    img: {
      oneX: "cardOfDay/rods/10-rods1x.png",
      twoX: "cardOfDay/rods/10-rods2x.png",
      threeX: "cardOfDay/rods/10-rods3x.png",
    },
  },
  {
    id: 40,
    name: "Паж Жезлов",
    description: [
      "Карта Таро говорит, что вы спонтанно увлечётесь чем-то новым, что разжигает интерес и желание: учёбой, стартапом, хобби, новой работой или отношениями. Чтобы набраться опыта, вы начнёте изучать разную информацию, почувствуете в этом свой потенциал и пробудившуюся жажду приключений. Поэтому Паж Жезлов советует прислушаться к своим желаниям, но быть аккуратными и не ввязываться в сомнительные авантюры.",
      "Карта дня говорит проявить энтузиазм и амбиции, действовать активно и дерзко. Используйте возможность, пока вы «горите». Иначе вернуться к этой идее позже уже не сможете — запал и энергия пропадут.",
    ],
    img: {
      oneX: "cardOfDay/rods/pazh-rods1x.png",
      twoX: "cardOfDay/rods/pazh-rods2x.png",
      threeX: "cardOfDay/rods/pazh-rods3x.png",
    },
  },
  {
    id: 41,
    name: "Рыцарь Жезлов",
    description: [
      "По карте Рыцарь Жезлов день наполнен активностью и суетой. Двигаясь к своим целям, вы совершите много бесполезных действий. Например, опаздывая, начнёте бегать по дому, собирая забытое, вместо того, чтобы медленно пройти один раз и всё взять.",
      "Кроме того, карта дня отражает развитие, адреналин, готовность рисковать. Вы будете проявлять себя, что-то доказывать, с напором добиваться своего, но быстро перегорите. Поэтому не упустите шанс приблизиться к желаемому.",
    ],
    img: {
      oneX: "cardOfDay/rods/knight-rods1x.png",
      twoX: "cardOfDay/rods/knight-rods2x.png",
      threeX: "cardOfDay/rods/knight-rods3x.png",
    },
  },
  {
    id: 42,
    name: "Королева Жезлов",
    description: [
      "Карта Таро говорит о результатах по важному вопросу: повышение, рост зарплаты, признание и др. Поэтому сегодня вы будете на виду и проведёте презентацию или совещание, посетите конференцию, вечеринку. Будьте яркими и уверенными в себе.",
      "При этом карта дня советует не делать всё самостоятельно, а использовать свои связи и выстраивать правильную коммуникацию. Но не манипулируйте людьми, а поддерживайте хорошие отношения для надёжной опоры своего положения.",
    ],
    img: {
      oneX: "cardOfDay/rods/queen-rods1x.png",
      twoX: "cardOfDay/rods/queen-rods2x.png",
      threeX: "cardOfDay/rods/queen-rods3x.png",
    },
  },
  {
    id: 43,
    name: "Король Жезлов",
    description: [
      "Вы уже много чего повидали и имеете большой опыт. Поэтому Король Жезлов советует не стремиться к масштабу и росту, а заняться укреплением своего положения и контролем. Сегодня от вашего мнения будет многое зависеть, вам придётся принимать решения, возможно, даже конфликтовать с другими. Но карта Таро советует не перегибать с доминированием.",
      "Также карта дня указывает на желание проявлять харизму, демонстрировать статус, совершать много действий, чтобы потешить своё самолюбие. Например, преподнесёте дорогой подарок не из искренних соображений, а чтобы показать собственную щедрость и крутизну.",
    ],
    img: {
      oneX: "cardOfDay/rods/king-rods1x.png",
      twoX: "cardOfDay/rods/king-rods2x.png",
      threeX: "cardOfDay/rods/king-rods3x.png",
    },
  },
  {
    id: 44,
    name: "Туз Кубков",
    description: [
      "Ощутите переизбыток эмоций после какой-то ситуации. Карта дня отражает как позитивные эмоции (радость на грани эйфории, опьяняющую влюблённость в человека или дело), так и негативные (агрессию, истерику). Карта Таро говорит, что вы полностью сосредоточитесь на эмоциональном всплеске, не будете воспринимать других людей и их позицию в данной ситуации, откажетесь от компромиссов.",
      "Туз Кубков указывает на новое очень сильное чувство или отношение к кому-то или чему-то и проявление своей позиции. Но эмоции нестабильны, поэтому если не дать этому чувству развитие, то ваша реакция продлится недолго.",
    ],
    img: {
      oneX: "cardOfDay/cups/1-cups1x.png",
      twoX: "cardOfDay/cups/1-cups2x.png",
      threeX: "cardOfDay/cups/1-cups3x.png",
    },
  },
  {
    id: 45,
    name: "Двойка Кубков",
    description: [
      "Двойка Кубков советует идти навстречу другим людям, не отказывайтесь от предложенного сотрудничества. Коллега позовёт начать с ним новый интересный проект. Если не будете ждать и сами обратитесь к начальству, то получите прибавку к зарплате. Узнаете, что ваши чувства к человеку взаимны.",
      "Карта дня предупреждает не строить воздушных замков. Проект на работе может оказаться чересчур сложным, и вы откажетесь от него. В любви откроете друг другу чувства, сходите на пару свиданий и разойдётесь, не начав отношения. Карта Таро указывает на взаимность и первый шажок к желаемому, но ничего не обещает.",
    ],
    img: {
      oneX: "cardOfDay/cups/2-cups1x.png",
      twoX: "cardOfDay/cups/2-cups2x.png",
      threeX: "cardOfDay/cups/2-cups3x.png",
    },
  },
  {
    id: 46,
    name: "Тройка Кубков",
    description: [
      "Намечается праздник: награда, корпоратив, день рождения, свидание. Расслабьтесь и погрузитесь в атмосферу веселья или сами создайте её. Если вам грустно, то карта Таро советует обратиться к друзьям за поддержкой и приятной средой.",
      "Много людей вовлекутся в происходящее. Если собраться с друзьями и поболтать — это нормально, то в отношениях лишние люди ничего хорошего не сулят. Карта дня предупреждает вас не быть поверхностными и не соглашаться на разные авантюры.",
    ],
    img: {
      oneX: "cardOfDay/cups/3-cups1x.png",
      twoX: "cardOfDay/cups/3-cups2x.png",
      threeX: "cardOfDay/cups/3-cups3x.png",
    },
  },
  {
    id: 47,
    name: "Четвёрка Кубков",
    description: [
      "Четвёрка Кубков — любая ситуация в жизни, выбор или решение вам не понравятся. Есть стабильность, комфорт, безопасность, но вас это не радует. Вам скучно: всё не так и не нравится, всем недовольны, а чего хотите — сами не знаете. Нет ни активности, ни обновлений.",
      "Карта Таро предупреждает, что вы рискуете упустить из виду интересные возможности, которые разворачиваются вокруг вас. Они разнообразят жизнь и наполнят её красками. Но вы их не замечаете или выбираете не замечать, потому что они тоже не нравятся. Карта дня советует эмоционально перегрузиться. Подумайте, что мешает выбраться из рутины.",
    ],
    img: {
      oneX: "cardOfDay/cups/4-cups1x.png",
      twoX: "cardOfDay/cups/4-cups2x.png",
      threeX: "cardOfDay/cups/4-cups3x.png",
    },
  },
  {
    id: 48,
    name: "Пятёрка Кубков",
    description: [
      "Ваш эмоциональный фон будет нестабилен. Карта Таро говорит, что вас выбьет из равновесия, день пойдёт насмарку. Столкнётесь с неприятной ситуацией, которая вызовет у вас глубокие переживания (разочарование, разбитость, печаль). Карта дня предупреждает — вы что-то потеряете и будете оплакивать: работу, проекты, надежды, друзей или отношения. Может возникнуть чувство одиночества.",
      "Пятёрка Кубков говорит, что чувствовать эти эмоции больно, но важно их не подавлять, а прожить. Так вы по достоинству оцените то, что имеете в жизни, проведёте самоанализ, лучше познакомитесь с собой и своими чувствами.",
    ],
    img: {
      oneX: "cardOfDay/cups/5-cups1x.png",
      twoX: "cardOfDay/cups/5-cups2x.png",
      threeX: "cardOfDay/cups/5-cups3x.png",
    },
  },
  {
    id: 49,
    name: "Шестёрка Кубков",
    description: [
      "Окунётесь в приятные воспоминания о прошлом. Шестёрка Кубков предлагает подумать, какой урок можно извлечь из пережитых событий. Возможно, вы возобновите отношения с кем-то или человек сам проявит инициативу.",
      "Карта Таро указывает на символический подарок, а при необходимости на помощь. В последнем случае попросите поддержки у родных или старых друзей.",
    ],
    img: {
      oneX: "cardOfDay/cups/6-cups1x.png",
      twoX: "cardOfDay/cups/6-cups2x.png",
      threeX: "cardOfDay/cups/6-cups3x.png",
    },
  },
  {
    id: 50,
    name: "Семёрка Кубков",
    description: [
      "Предстоит выбор из множества вариантов. Но Карта Таро говорит, что это будет иллюзия, и ощутимого результата вы не получите. Например, зарегистрируетесь на сайте знакомств и решите, что у вас куча кандидатов; или начнёте мечтать о повышении на работе, но не будете ничего делать.",
      "Семёрка Кубков указывает на принятие желаемого за действительное. Могут обмануть и со стороны, но вы сами будете рады обмануться, например, «клюнете» на лёгкий заработок в интернете. Не доверяйте сомнительным предложениям.",
    ],
    img: {
      oneX: "cardOfDay/cups/7-cups1x.png",
      twoX: "cardOfDay/cups/7-cups2x.png",
      threeX: "cardOfDay/cups/7-cups3x.png",
    },
  },
  {
    id: 51,
    name: "Восьмёрка Кубков",
    description: [
      "Вы осознанно откажетесь от старого образа жизни или его части (к примеру, машину смените на общественный транспорт, бросите вредные привычки), уйдёте откуда-то (с нелюбимой работы, из неперспективных отношений). Восьмёрка Кубков говорит, что иллюзии развеются, а реальность разочарует. Изменения могут оказаться для вас болезненными и печальными, но всё равно выбирайте себя. Будет ощущение, что жизнь не заканчивается на этом.",
      "Карта дня говорит о поиске более лучшей жизни. Существующая жизнь неплоха, но есть желание дальше развиваться. Карта Таро предупреждает не рубить с плеча, вдруг что-то ещё можно изменить.",
    ],
    img: {
      oneX: "cardOfDay/cups/8-cups1x.png",
      twoX: "cardOfDay/cups/8-cups2x.png",
      threeX: "cardOfDay/cups/8-cups3x.png",
    },
  },
  {
    id: 52,
    name: "Девятка Кубков",
    description: [
      "Карта Таро указывает на здоровый эгоизм. У вас будет весёлое настроение, которым не захотите делиться, решите насладиться одиночеством. К примеру, на работе закажете доставку еды, отложите дела и покайфуете. А стоя в пробке, включите зажигательные песни и начнёте подпевать. Дома примете ванну и посмотрите интересный фильм.",
      "Карта дня говорит, что захочется взять отгул на работе, либо у вас скоро первый день отпуска. Вы почувствуете себя крутыми, испытаете радость и удовольствие, пока другие домашние заняты делами. Девятка Кубков советует не впадать в эгоцентризм. Отдохнув, тоже порадуйте любимых, например, вкусным ужином.",
    ],
    img: {
      oneX: "cardOfDay/cups/9-cups1x.png",
      twoX: "cardOfDay/cups/9-cups2x.png",
      threeX: "cardOfDay/cups/9-cups3x.png",
    },
  },
  {
    id: 53,
    name: "Десятка Кубков",
    description: [
      "Десятка Кубков — нет развития, движения, приобретений. Всё и так уже хорошо. Иногда карта показывает пусть и счастливый, но финал проекта, отношений (тема закрыта, и все счастливы, но уже по отдельности).",
      "Испытаете сильные переживания, связанные с семьёй. Карта дня отражает как положительные эмоции (допустим, мирное расставание с прежними мечтами: перестанете искать новую квартиру, ведь и старая ещё ничего), так и отрицательные (коллективная ссора).",
    ],
    img: {
      oneX: "cardOfDay/cups/10-cups1x.png",
      twoX: "cardOfDay/cups/10-cups2x.png",
      threeX: "cardOfDay/cups/10-cups3x.png",
    },
  },
  {
    id: 54,
    name: "Паж Кубков",
    description: [
      "Очаруетесь новыми чувствами, будете мечтать и романтизировать своё будущее. Карта Таро говорит, что вы чем-то загоритесь и проявите много энтузиазма. Но не будете делать первых шагов в эту сторону, потому что не знаете, как лучше. Паж Кубков предупреждает не быть слишком инфантильными.",
      "Получите небольшой подарок, начнёте скромную любовную переписку. Вам предложат новую работу или проект, вдохновляющий на разные идеи. У вас проснётся эстетика и креативность. Это всё будет вам очень интересно, но непонятно. Карта дня советует отбросить сомнения, страхи, тревоги. Расслабьтесь и получайте удовольствие.",
    ],
    img: {
      oneX: "cardOfDay/cups/pazh-cups1x.png",
      twoX: "cardOfDay/cups/pazh-cups2x.png",
      threeX: "cardOfDay/cups/pazh-cups3x.png",
    },
  },
  {
    id: 55,
    name: "Рыцарь Кубков",
    description: [
      "Начнёте активно куда-то вовлекаться и проявлять больше интереса. Рыцарь Кубков указывает на новое хобби, проект на работе, человека. В голове может быть много фантазий и идей относительно объекта интереса. Можете делать громкие высказывания: это моя самая лучшая работа, идеальный партнёр. Но карта дня советует не идеализировать слишком сильно, иначе потом наступит этап обесценивания.",
      "Карта Таро советует вам быть активными, креативными, зажигать энтузиазмом других людей. Действуйте решительно и реализуйте свои мечты, но будьте объективны. Ставьте перед собой достижимые цели с обозримыми временными рамками.",
    ],
    img: {
      oneX: "cardOfDay/cups/knight-cups1x.png",
      twoX: "cardOfDay/cups/knight-cups2x.png",
      threeX: "cardOfDay/cups/knight-cups3x.png",
    },
  },
  {
    id: 56,
    name: "Королева Кубков",
    description: [
      "Вы научились управлять эмоциями, но пока не всегда хорошо получается. Карта дня говорит о ситуации, которую вы воспримете близко к сердцу. Не зацикливайтесь, не делайте поспешных выводов, не проецируйте на окружающих свои эмоции и не устраивайте истерики. Королева Кубков советует оставаться в равновесии и не вестись на провокации.",
      "Лучше уделите время себе, разберитесь в собственных чувствах. Окружите себя комфортом и уютом, порадуйте чем-то приятным, отложите все сложные и бытовые вопросы до завтра. Но карта Таро предупреждает быть аккуратными с финансами, избегать больших импульсивных трат.",
    ],
    img: {
      oneX: "cardOfDay/cups/queen-cups1x.png",
      twoX: "cardOfDay/cups/queen-cups2x.png",
      threeX: "cardOfDay/cups/queen-cups3x.png",
    },
  },
  {
    id: 57,
    name: "Король Кубков",
    description: [
      "Вокруг вас много переживаний других людей, а вам важно сохранять спокойствие и уверенность. Король Кубков советует читать чувства окружающих и управлять ими — следите, что сказать, как сказать, когда сказать. Карта дня говорит, что вы можете получить личную выгоду или выступить для другого психологом. Но не злоупотребляйте своим положением.",
      "Карта Таро указывает на неопределённость: не будете знать, чего хотите. Ощутите одиночество и жажду внимания, но и начнёте мечтать о свободе, свободе чувств. Сходите с друзьями в караоке — так и среди людей побудете, и чувства выплеснете, и обязательств не будет.",
    ],
    img: {
      oneX: "cardOfDay/cups/king-cups1x.png",
      twoX: "cardOfDay/cups/king-cups2x.png",
      threeX: "cardOfDay/cups/king-cups3x.png",
    },
  },
  {
    id: 58,
    name: "Шут",
    description: [
      "Если сегодня вы хотите получить результат или что-то конкретное — не выйдет. Зато это отличное время, чтобы самим действовать, даже если страшно. Карта Таро говорит о возможности начать новое, в чём у вас нет опыта. Не думайте долго, а прислушайтесь к себе и сделайте это. Если что-то пойдёт не так, вы получите опыт.",
      "Проявите открытость и любознательность, не относитесь ко всему слишком серьёзно. Но Шут предупреждает не быть чересчур инфантильными и безрассудными, не действовать хаотично.",
      "Карта дня указывает на вопросы, связанные с детьми. Возможно, вы давно не уделяли достаточно времени ребёнку.",
    ],
    img: {
      oneX: "cardOfDay/major/0-major1x.png",
      twoX: "cardOfDay/major/0-major2x.png",
      threeX: "cardOfDay/major/0-major3x.png",
    },
  },
  {
    id: 59,
    name: "Маг",
    description: [
      "День активных и решительных действий. Не ждите помощи, а сами разберитесь со всем. Маг советует смотреть на вещи под другим углом, мыслить нестандартно, проявлять инициативу и гибкость. В ваших руках уже есть все инструменты для воплощения желаемого.",
      "Карта дня говорит, что вы в силах исправить любую ситуацию в свою пользу, если начнёте влиять на неё. Но не будьте слишком расчётливыми и не манипулируйте людьми. Используйте энергию этого дня, чтобы гармонизировать проблемную сферу жизни. Карта Таро предупреждает — если вы поддадитесь страхам и сомнениям, то впустую сольёте свои силы.",
    ],
    img: {
      oneX: "cardOfDay/major/1-major1x.png",
      twoX: "cardOfDay/major/1-major2x.png",
      threeX: "cardOfDay/major/1-major3x.png",
    },
  },
  {
    id: 60,
    name: "Верховная жрица",
    description: [
      "Карта Таро говорит — в жизни происходит то, что вы не замечаете. Сегодня получите важное озарение, которое откроет вам глаза, или узнаете чей-то секрет. Верховная Жрица советует не делать поспешных выводов и не проявлять активность. Отойдите на второй план и наблюдайте за происходящим. Смотрите на события под другим углом, чтобы увидеть скрытое.",
      "Уделите время себе и отвлекитесь от повседневной рутины, но не будьте чересчур холодными и скрытными. Ищите мудрость внутри и снаружи. Карта дня рекомендует прислушиваться к интуиции, она поможет вам осознать свою силу и принять правильные решения.",
    ],
    img: {
      oneX: "cardOfDay/major/2-major1x.png",
      twoX: "cardOfDay/major/2-major2x.png",
      threeX: "cardOfDay/major/2-major3x.png",
    },
  },
  {
    id: 61,
    name: "Императрица",
    description: [
      "Карта дня советует направить усилия на свою женскую энергию (она есть и у мужчин). Будьте более естественными и мягкими, дарите любовь близким, окружите их и себя красотой, уютом. Не погружайтесь в чрезмерную опеку, суету и рутину. Императрица подсказывает, что сегодня нужно больше показывать свои таланты и уникальные способности.",
      "Позаботьтесь о себе и собственных начинаниях. Карта Таро предлагает подумать, чем вы наполняете свою жизнь. Больше развивайте свои сильные стороны, стремитесь к внутренней гармонии и духовному росту. Не пытайтесь переделать себя, кому-то подражать или подстраиваться.",
    ],
    img: {
      oneX: "cardOfDay/major/3-major1x.png",
      twoX: "cardOfDay/major/3-major2x.png",
      threeX: "cardOfDay/major/3-major3x.png",
    },
  },
  {
    id: 62,
    name: "Император",
    description: [
      "Император говорит, чтобы вы взяли ситуацию под свой контроль и навели в ней порядок для поддержания желаемой стабильности. Это может касаться как, например, рабочего проекта, так и жизни в целом. Карта Таро советует не двигаться вперёд, а структурировать проблемы и разумно начать их решать.",
      "Проявите свои лидерские качества, усильте дисциплину. Но не уходите в тотальный контроль и несгибаемость, шире смотрите на происходящее. Оставайтесь справедливыми и сочувствующими. Возможно, вы станете покровителем для другого человека. Или карта дня говорит, что вы сами обратитесь к представителю власти.",
    ],
    img: {
      oneX: "cardOfDay/major/4-major1x.png",
      twoX: "cardOfDay/major/4-major2x.png",
      threeX: "cardOfDay/major/4-major3x.png",
    },
  },
  {
    id: 63,
    name: "Иерофант (Жрец)",
    description: [
      "Нужно передать свои знания и мудрость. Карта дня говорит, что вы станете наставником для нового коллеги на работе, проведёте обучающий тренинг по любимой теме или поможете детям с уроками. Но не перегибайте с нравоучениями и не будьте всезнайкой.",
      "Жрец — отличный день, чтобы подтянуть свои знания или обучиться новому. Найдите подходящего наставника: психолога, консультанта, учителя.",
      "Карта Таро советует расширять горизонты, смотреть на отношения с людьми под другим углом — любая ситуация или человек вас чему-то учит. Не делите события на чёрные и белые, а используйте полученный опыт для роста.",
    ],
    img: {
      oneX: "cardOfDay/major/5-major1x.png",
      twoX: "cardOfDay/major/5-major2x.png",
      threeX: "cardOfDay/major/5-major3x.png",
    },
  },
  {
    id: 64,
    name: "Влюблённые",
    description: [
      "Влюблённые предупреждают — важно сделать выбор между индивидуальным и социальным: жить с родителями или самостоятельно, работа или отношения, развестись или сохранить брак, один партнёр или второй.",
      "Это ответственное решение с необратимыми последствиями. Но карта Таро говорит действовать, даже если есть искушения, испытания. Слушайте себя и следуйте за сердцем, а не за стереотипами в обществе. При бездействии ситуация сама разрешится, и не факт, что в вашу пользу.",
      "Карта дня говорит об ответственном партнёрстве. Возможность создать крепкую семью или начать проект, бизнес с подходящим человеком.",
    ],
    img: {
      oneX: "cardOfDay/major/6-major1x.png",
      twoX: "cardOfDay/major/6-major2x.png",
      threeX: "cardOfDay/major/6-major3x.png",
    },
  },
  {
    id: 65,
    name: "Колесница",
    description: [
      "Вы сделали правильный выбор, и теперь ход событий ускорится. Оставьте чувства в стороне, сфокусируйтесь на саморазвитии и реализации в обществе. ",
      "Колесница указывает на активность, необходимость бороться и преодолевать препятствия. У вас есть все шансы успешно завершить проект, получить повышение, закрыть кредит, завоевать сердце любимого человека. Но карта дня советует не торопить события ещё больше. А если будете бездействовать, то события выйдут из-под контроля.",
      "Карта Таро связана с дорогой и передвижениями. Вы узнаете о командировке, устроите свидание по городу, решите выбраться на природу.",
    ],
    img: {
      oneX: "cardOfDay/major/7-major1x.png",
      twoX: "cardOfDay/major/7-major2x.png",
      threeX: "cardOfDay/major/7-major3x.png",
    },
  },
  {
    id: 66,
    name: "Сила",
    description: [
      "Даже если вокруг вас хаос, Сила советует изучить себя: прислушайтесь к инстинктам и делайте то, что нравится. Избегайте давления, уберите всё, что лишает вас сил. Карта дня говорит о необходимости контактировать с телом: займитесь физической работой или спортом. Почувствуйте и развивайте внутреннюю силу, но не злоупотребляйте ей, усмирите агрессию, работайте над харизмой.",
      "Затем карта Таро советует наводить порядок во внешней сфере. Проявите свою мудрость и силу духа. Не прите напролом, чтобы добиться желаемого, а действуйте из тени, проявляя природную хитрость и обаяние. Управляйте, не управляя.",
    ],
    img: {
      oneX: "cardOfDay/major/8-major1x.png",
      twoX: "cardOfDay/major/8-major2x.png",
      threeX: "cardOfDay/major/8-major3x.png",
    },
  },
  {
    id: 67,
    name: "Отшельник",
    description: [
      "Вы достигли цели и не знаете, что делать дальше. Или думаете о смысле жизни и вашем месте в ней. Отшельник советует не торопиться с выводами и действиями, иначе потом поймёте, что это не актуально. Дайте себе время обновиться. Вы нуждаетесь в личном пространстве и уединении.",
      "Карта дня говорит, что нужно взять паузу. Не проявляйте активность, а погрузитесь в себя. Все ответы находятся внутри. Проведите самоанализ и переосмыслите свои жизненные ценности. Возможно, вы поставите перед собой новые цели.",
      "Карта Таро предупреждает — всё хорошо в меру. Не замыкайтесь в себе и не отрекайтесь от общества.",
    ],
    img: {
      oneX: "cardOfDay/major/9-major1x.png",
      twoX: "cardOfDay/major/9-major2x.png",
      threeX: "cardOfDay/major/9-major3x.png",
    },
  },
  {
    id: 68,
    name: "Колесо Фортуны",
    description: [
      "Карта Таро указывает на цикличность. Проанализируйте жизнь, какие ситуации в ней повторяются. Внутри у вас есть постоянная негативная установка или блок, которые не прорабатываются. Из-за этого вы снова рискуете наступить на те же «грабли».",
      "Колесо Фортуны говорит о возможности выйти на новый уровень. Но для этого нужно по-новому применить свои навыки. Не будьте слишком консервативными, действуйте разумно и рискованно. Для достижения желаемого приложите максимум усилий.",
      "Карта дня подсказывает, что ваши построенные планы могут резко измениться из-за непредсказуемых событий. Действуйте по ситуации.",
    ],
    img: {
      oneX: "cardOfDay/major/10-major1x.png",
      twoX: "cardOfDay/major/10-major2x.png",
      threeX: "cardOfDay/major/10-major3x.png",
    },
  },
  {
    id: 69,
    name: "Справедливость",
    description: [
      "Справедливость раздаёт по заслугам: сдельная оплата труда, бартер, сдача экзамена, как подготовились. Если не заплатили налоги, не выполнили обязанности — ждите штрафа или другого наказания. Сегодня надо соблюдать все правила и нормы.",
      "Карта Таро советует грамотно составлять документы, официально их заверять. Возможно, придётся решать бюрократические или судебные вопросы в государственных органах. Если в документах будут неточности, то получите отказ.",
      "Или же карта дня говорит, что у вас не будет желания идти навстречу людям. Но не закрывайте глаза на проблемы, они могут привести к общему коллапсу.",
    ],
    img: {
      oneX: "cardOfDay/major/11-major1x.png",
      twoX: "cardOfDay/major/11-major2x.png",
      threeX: "cardOfDay/major/11-major3x.png",
    },
  },
  {
    id: 70,
    name: "Повешенный",
    description: [
      "Карта Таро говорит, что окажетесь в условиях, которые вам не понравятся. Но вы решите не менять ситуацию, а приспособиться, несмотря на все неудобства: сверхурочная работа, свидание с неинтересным человеком, свекровь (тёща) поселится у вас. Карта дня говорит не становиться жертвой обстоятельств, не начинать обвинять всех вокруг, кроме себя.",
      "Повешенный советует по-другому взглянуть на происходящее. Ничто никуда не движется, потому что ваше мировоззрение устарело. Ваши привычные действия уже не работают. Для развития нужна трансформация. Поймите, какие установки вас ограничивают, и измените их.",
    ],
    img: {
      oneX: "cardOfDay/major/12-major1x.png",
      twoX: "cardOfDay/major/12-major2x.png",
      threeX: "cardOfDay/major/12-major3x.png",
    },
  },
  {
    id: 71,
    name: "Смерть",
    description: [
      "Карта дня указывает на завершение важного этапа жизни и отпускание того, что больше вас не развивает: окончательное расставание с партнёром, закрытие старого проекта, банкротство фирмы и сокращение или выход на пенсию.",
      "Спокойно уйдёт то, что больше не ваше. Не цепляйтесь за это и не пытайтесь исправить. Смерть советует не отрицать и не подавлять потерю, а прожить её.",
      "Карта Таро говорит не погружаться в тотальное переживание и не считать, что смысл жизни потерян. Даже если пока не понятно, как будет дальше, займитесь поиском нового и изменением своей жизни. Замедлитесь и всё тщательно обдумайте.",
    ],
    img: {
      oneX: "cardOfDay/major/13-major1x.png",
      twoX: "cardOfDay/major/13-major2x.png",
      threeX: "cardOfDay/major/13-major3x.png",
    },
  },
  {
    id: 72,
    name: "Умеренность",
    description: [
      "Сохраняйте спокойствие и баланс между сферами жизни: работой и отдыхом, личным временем и общением с близкими, удовольствием и дисциплиной. Но карта дня говорит, чтобы вы не уходили в крайность. Умеренность нужна даже в умеренности, иначе заскучаете.",
      "Отложите спешку, добавьте больше осознанности. Вы столкнётесь с испытаниями на проверку терпения. Не ускоряйте события, а ждите. Карта Таро советует вспомнить о внутренней силе. Она поможет вам уверенно стоять на земле, находиться в теле и настоящем моменте, усмирить эмоции, быть собранными — тогда решите проблемы и придёте к компромиссу в спорах.",
    ],
    img: {
      oneX: "cardOfDay/major/14-major1x.png",
      twoX: "cardOfDay/major/14-major2x.png",
      threeX: "cardOfDay/major/14-major3x.png",
    },
  },
  {
    id: 73,
    name: "Дьявол",
    description: [
      "Почувствуете зависимость: возьмёте кредит на желаемое, останетесь работать сверхурочно для выслуги перед начальством, откажетесь от отдыха с друзьями из-за неодобрения партнёра. Карта Таро говорит трезво оценивать события и думать, хотите ли вы терять свободу. Остерегайтесь искушений, не поддавайтесь на манипуляции. Карта дня подсказывает — вас будут провоцировать на проявление негативных черт.",
      "Дьявол говорит, что вы загоритесь идеей и станете буквально одержимыми ей. Легче относитесь к задумке. Иначе если в перспективе она не реализуется, вы впадёте в депрессию или заведёте вредную привычку.",
    ],
    img: {
      oneX: "cardOfDay/major/15-major1x.png",
      twoX: "cardOfDay/major/15-major2x.png",
      threeX: "cardOfDay/major/15-major3x.png",
    },
  },
  {
    id: 74,
    name: "Башня",
    description: [
      "Карта дня предупреждает о неожиданном крушении планов и надежд. Если вы отправляетесь в отпуск, то готовьтесь к тому, что не уедете (самолёт перенесут, машина сломается).",
      "Карта Таро указывает на разрушение той части жизни, где вы чувствовали себя королями, может даже произойти ЧП. Например, лёгкое ДТП, если авто даёт ощущение, что вы выиграли эту жизнь. Или возникнет громкая и бурная ссора, но это не конец.",
      "Башня указывает на потрясения и крах ценностных ориентиров. Вам нужно переосмыслить жизнь. Старый мир рушится — это неизбежно, поэтому не сопротивляйтесь. Но проблемы не будут длиться долго.",
    ],
    img: {
      oneX: "cardOfDay/major/16-major1x.png",
      twoX: "cardOfDay/major/16-major2x.png",
      threeX: "cardOfDay/major/16-major3x.png",
    },
  },
  {
    id: 75,
    name: "Звезда",
    description: [
      "Звезда говорит, что день будет наполнен вдохновением. Не упустите этот момент, прислушайтесь к себе и своим желаниям, загоритесь какой-то идеей и поставьте себе цель. В будущем у вас есть хорошие перспективы. Но карта Таро советует не идеализировать события, людей и не убегать в фантазии.",
      "При проблемах карта дня указывает на свет в конце тоннеля. Появится надежда, что всё можно решить, но сил действовать не будет. Поэтому решите ждать, что всё само исправится. Отношения не нравятся, но надеетесь, что партнёр изменится. Или хотите развития на работе, но терпите — повышение возможно через 3 года.",
    ],
    img: {
      oneX: "cardOfDay/major/17-major1x.png",
      twoX: "cardOfDay/major/17-major2x.png",
      threeX: "cardOfDay/major/17-major3x.png",
    },
  },
  {
    id: 76,
    name: "Луна",
    description: [
      "Карта Таро указывает на непонятный и мутный день. Странные ситуации, ощущение предательства и интриг за спиной, противоречивая информация. Но вы видите картину не такой, какой она есть на самом деле. Замечаете лишь то, что есть у вас и требует проработки. Если цепляет чужая ложь, значит вы кого-то или себя обманываете, или запрещаете себе лгать.",
      "Луна советует действовать интуитивно и мыслить нестандартно, все решения скрыты внутри вас. Даже если колеблетесь и чувствуете неуверенность, карта Таро говорит не искажать действительность, не поддаваться необоснованным страхам и не впадать в ступор.",
    ],
    img: {
      oneX: "cardOfDay/major/18-major1x.png",
      twoX: "cardOfDay/major/18-major2x.png",
      threeX: "cardOfDay/major/18-major3x.png",
    },
  },
  {
    id: 77,
    name: "Солнце",
    description: [
      "Вы чувствуете безопасность и глубокое внутреннее удовлетворение — понимаете, кто вы, и рады этому. Карта Таро советует на секунду остановиться и насладиться тем, что имеете. Ощутите удовольствие, наслаждение жизнью, беззаботность. Но не теряйте мотивацию к развитию и не засиживайтесь на одном месте слишком долго.",
      "Карта дня говорит следить за своим эго и не быть чересчур нарциссичными. Если сделали ошибку, то признайте это и двигайтесь дальше.",
      "Солнце указывает, что окажетесь в комфортном месте, где можно расслабиться, снова вернуться в детство и почувствовать себя счастливыми. Насладитесь этим.",
    ],
    img: {
      oneX: "cardOfDay/major/19-major1x.png",
      twoX: "cardOfDay/major/19-major2x.png",
      threeX: "cardOfDay/major/19-major3x.png",
    },
  },
  {
    id: 78,
    name: "Страшный суд",
    description: [
      "Страшный Суд говорит, что прошлое даст о себе знать: получите звонок, сообщение, узнаете из соцсетей, СМИ. Тайны, факты, новая информация, люди, отношения — то, что ранее было не доделано и забыто свалится на вас, как снег на голову, и нарушит привычный ритм жизни.",
      "Карта Таро советует исправить ошибки, чтобы изменить своё положение. Если вчера не было работы, а сегодня позвонит друг, с которым вы давно в ссоре, и предложит должность с высокой зарплатой — нужно помириться.",
      "Карта дня говорит, что займётесь реорганизацией своей жизни: проанализируете бизнес, личные цели и желания, финансы, отношения.",
    ],
    img: {
      oneX: "cardOfDay/major/20-major1x.png",
      twoX: "cardOfDay/major/20-major2x.png",
      threeX: "cardOfDay/major/20-major3x.png",
    },
  },
  {
    id: 79,
    name: "Мир",
    description: [
      "Мир советует объединить ум, душу и тело. Что излучаете, то и получаете, красота начинается в вас. Будьте проще, расширяйте сознание, развивайтесь, именно вы творите свою реальность.",
      "Карта Таро указывает на мирное и логичное завершение ситуаций. Вы давно к этому готовы. Закрытие проекта или целого бизнеса, финал отношений, окончательное возвращение домой из-за границ.",
      "Карта дня связана с большим количеством информации, контактов, связей. Путешествия, межкультурные контакты, международные сотрудничества и командировки, общение в интернете. Это может быть как работа, так и отношения на расстоянии.",
    ],
    img: {
      oneX: "cardOfDay/major/21-major1x.png",
      twoX: "cardOfDay/major/21-major2x.png",
      threeX: "cardOfDay/major/21-major3x.png",
    },
  },
];

export const shuffledCards = shuffleArray(cards);
