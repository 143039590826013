import React, { useEffect, useRef, useState } from "react";

import { navigate } from "gatsby";

import { useMarketingHook } from "@/marketing/marketingHook";

import CardResult from "./cardResult";
import CardRotate from "./cardRotate";
import CardsList from "./cardsList";
import { shuffledCards } from "./constants";
import { CardType, Step } from "./types";

import "./styles.scss";

const CardOfDay = () => {
  const { reachCustomYandexMetricsGoal } = useMarketingHook();
  const [cards] = useState(shuffledCards);
  const [currentStep, setCurrentStep] = useState<Step>(Step.LIST);
  const [selectedCard, setSelectedCard] = useState<CardType | null>(null);
  const wrapperRef = useRef(null);

  const handleCardSelect = (card: CardType) => {
    reachCustomYandexMetricsGoal("card_of_the_day_click");
    setSelectedCard(card);
    setCurrentStep(Step.ROTATE);
    if (window.innerWidth < 768) {
      window.scrollTo({
        top: 50,
        behavior: "instant",
      });
    } else {
      document.body.scrollTo(0, 100);
    }
  };

  const handleRotateComplete = () => {
    setCurrentStep(Step.RESULT);
  };

  const handleBackToList = () => {
    navigate("/catalog");
  };

  useEffect(() => {
    reachCustomYandexMetricsGoal("card_of_the_day");
  }, [reachCustomYandexMetricsGoal]);

  return (
    <div ref={wrapperRef} className="card-of-day-wrapper">
      <h1>Карта дня от Lunaro</h1>
      {currentStep === Step.LIST && <CardsList cards={cards} onClick={handleCardSelect} />}
      {currentStep === Step.ROTATE && selectedCard && (
        <CardRotate onComplete={handleRotateComplete} />
      )}
      {currentStep === Step.RESULT && selectedCard && (
        <CardResult card={selectedCard} onBack={handleBackToList} />
      )}
    </div>
  );
};

export default CardOfDay;
