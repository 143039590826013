import React from "react";
import "./styles.scss";

const Stars = () =>
  (
    <div className="stars">
      <div className="stars__left">
        <div className="big-star" />
        <div className="small-star" />
        <div className="small-star" />
        <div className="small-star" />
      </div>
      <div className="stars__right">
        <div className="small-star" />
        <div className="big-star" />
        <div className="small-star" />
        <div className="small-star" />
        <div className="big-star" />
      </div>
    </div>
  );

export default Stars;
