import React, { useEffect, useRef } from "react";

import { gsap } from "gsap";

import { useMarketingHook } from "@/marketing/marketingHook";

import { CardRotateProps } from "./types";

import "./styles.scss";

const CardRotate = ({ onComplete }: CardRotateProps) => {
  const { reachCustomYandexMetricsGoal } = useMarketingHook();
  const cardRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!cardRef.current) return;
    gsap.set(cardRef.current, { perspective: 800 });
    gsap.set(".back", { rotationY: 180 });
    gsap.set([".front", ".back"], { backfaceVisibility: "hidden" });

    const rotateCard = () => {
      try {
        gsap.to(cardRef.current, {
          rotationY: 720,
          transformOrigin: "center",
          transformStyle: "preserve-3d",
          duration: 3,
          ease: "none",
          onComplete,
        });
      } catch (error) {
        reachCustomYandexMetricsGoal("card_of_the_day_view_error");
      }
    };
    rotateCard();
  }, [onComplete, reachCustomYandexMetricsGoal]);

  return (
    <div className="card-wrapper">
      <div className="card-wrapper__card-img">
        <div ref={cardRef} className="card-container">
          <div className="card-wrapper__card-img-face front">
            <img src="/cardOfDay/card.svg" alt="Front Card" />
          </div>
          <div className="card-wrapper__card-img-face back">
            <img src="/cardOfDay/card.svg" alt="Back Card" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardRotate;
