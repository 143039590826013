import React, { useEffect } from "react";

import "./styles.scss";
import { Button, ButtonSizeEnum } from "@/components/common/button";
import { useMarketingHook } from "@/marketing/marketingHook";

import { CardResultProps } from "./types";

const CardResult = ({ card, onBack }: CardResultProps) => {
  const { reachCustomYandexMetricsGoal } = useMarketingHook();

  useEffect(() => {
    reachCustomYandexMetricsGoal("card_of_the_day_view");
  }, [reachCustomYandexMetricsGoal]);
  return (
    <div className="card-result">
      <div className="card-result__container">
        <div className="card-result__container__img">
          <picture>
            <source
              srcSet={`${card.img.oneX} 1x,
              ${card.img.twoX} 2x,
              ${card.img.threeX} 3x`}
            />
            <img itemProp="image" src={card.img.oneX} alt="card" loading="lazy" />
          </picture>
        </div>
        <div className="card-result__content">
          <p className="card-result__content your-card">Ваша карта</p>
          <h2>{card.name}</h2>
          {card.description.map((text, key) =>
            (
              <p key={key}>{text}</p>
            ))}
          <Button onClick={onBack} size={ButtonSizeEnum.Middle} text="Спросить таролога" />
        </div>
      </div>
    </div>
  );
};

export default CardResult;
