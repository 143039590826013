import React from "react";

import CardOfDay from "@/components/cardOfDay";
import Stars from "@/components/cardOfDay/cardsStars";
import "./styles.scss";

const CardOfDayPage = () =>
  (
    <div className="card-of-day">
      <Stars />
      <div className="card-of-day__page-width">
        <CardOfDay />
      </div>
    </div>
  );

export default CardOfDayPage;
